import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Menu from '../components/Menu';
import Nav from '../components/Nav';
import HeroSimple from '../components/HeroSimple';
import classnames from 'classnames';
import SEO from '../components/seo/SEO';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Template = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const title = data.markdownRemark.frontmatter.title;
  const excerpt = data.markdownRemark.frontmatter.excerpt;
  const html = data.markdownRemark.html;
  const video = data.markdownRemark.frontmatter.video || null;
  const windowSize = typeof window !== 'undefined' ? window.innerWidth : 0;
  const videoStyles = { width: `${windowSize * 0.65 <= 1175 ? windowSize * 0.65 : 1200}px`, height: `${windowSize * 0.65 <= 1175 ? windowSize * 0.65 * 0.5628205128205128 : 675}px` };
  return (
    <>
      <SEO />
      <div className="flex">
        <section title="content" className="w-full">
          <div className="gradient">
            <Nav />
            <HeroSimple
              heading={title}
              subText={"PRÉSENTATION DU COURS"}
              buttonText="Prochaine étape"
              handleClick={() => {
                navigate(next.frontmatter.path)
              }}
              subHeading={excerpt}
              showButton={next}
            />

          </div>
          <div className="container mx-auto mb-24">
            {video && <iframe className="mt-0 lg:-mt-12 mb:-mt-12 mb-10 block mx-auto z-50" style={videoStyles} title="video" src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
            <article className=" p-4 md:p-12 lg:p-12 shadow-md">
              <div className="markdown-content px-8 lg:px-0 w-full" dangerouslySetInnerHTML={{ __html: html }} />
            </article>

            <div className={classnames('flex', { 'justify-end': next }, { 'justify-between': prev && next })}>
              {prev && <Link className="hover:underline gradient shadow px-8 py-4 mt-4 rounded-full font-bold text-white no-underline hover:no-underline" to={prev.frontmatter.path}>Étape précédente</Link>}

              {next && <Link className=" hover:underline gradient shadow px-8 py-4 mt-4 rounded-full font-bold text-white no-underline hover:no-underline" to={next.frontmatter.path}>Étape suivante</Link>}
            </div>
          </div>
        </section>
        <section title="menu-cours" className="w-1/5 pt-24 hidden lg:block">
          <h3 className="text-center text-purple">Table of content</h3>
          <Menu menu={data.menu} current={data.markdownRemark.frontmatter} />
        </section>
      </div>

      <Contact />
      <Footer image={data.footerImage.childImageSharp.fluid} />

    </>
  )
}

export const query = graphql`
    query($pathSlug: String!, $tag: String!) {
        markdownRemark(frontmatter: {path: {eq: $pathSlug}}) {
            html,
            frontmatter {
                title,
                excerpt,
                video
            }
        }
        menu: allMarkdownRemark(filter: {frontmatter: {type: {eq: "step"}, tags: {eq: $tag}}}, sort: {order: ASC, fields: [frontmatter___step]}) {
            edges {
              node {
                frontmatter {
                  title
                  path
                  date,
                }
              }
            }
          },
          footerImage: file(relativePath: {eq: "Logo.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default Template;